<template>
  <section>
    <v-list two-line v-if="destinations.length">
      <template v-for="(destination, index) in destinations">
        <v-list-tile
          @click.stop="openDestDialog(destination)"
          :key="'tile-'+destination._id">
          <v-list-tile-content>
            <v-list-tile-title v-text="destination.presso" />
            <v-list-tile-sub-title v-text="getAddr(destination)" />
          </v-list-tile-content>
          <v-list-tile-action>
            <v-chip small :color="!destination.attivo ? 'grey dark-3' : 'success'" text-color="white" v-if="destination.tipo==0">
              <span class="hidden-sm-and-down">Destinazione</span>
              <span class="hidden-md-and-up">D</span>
            </v-chip>
            <v-chip small :color="!destination.attivo ? 'grey dark-3' : 'success'"  v-if="destination.tipo==1">
              <span class="hidden-sm-and-down">Provenienza</span>
              <span class="hidden-md-and-up">P</span>
            </v-chip>
            <v-chip small :color="!destination.attivo ? 'grey dark-3' : 'success'" text-color="white" v-if="destination.tipo==2">
              <span class="hidden-sm-and-down">Destinazione e Provenienza</span>
              <span class="hidden-md-and-up">DP</span>
            </v-chip>
            <v-icon @click="eliminaDest = true; destination = destination" color="error">delete</v-icon>
          </v-list-tile-action>
        </v-list-tile>
        <v-divider :key="destination._id" v-if="index < destinations.length - 1"></v-divider>
      </template>
    </v-list>
    <v-alert
      outline=""
      color="info"
      value="true"
      v-if="!destinations.length">
      Nessuna destinazione trovata
    </v-alert>
    <!-- modale destinazione -->
    <v-dialog
      v-model="showDest"
      transition="dialog-bottom-transition"
      :overlay="false"
      max-width="900">
      <v-card>
        <v-toolbar class="grey lighten-3" card>
          <v-toolbar-title>{{ modalTitle }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="closeDest()"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
        <v-form ref="formDestinazione" v-model="validFormDestinazione" lazy-validation>
            <v-container fluid>
              <v-layout row wrap>
                <v-flex xs12 lg6>
                  <v-layout row wrap>
                    <v-flex xs12 sm8>
                      <v-text-field
                        label="Presso"
                        v-model="destination.presso"
                        name="presso"
                        :rules="[$rules.required, $rules.maxlength(60)]"
                      />
                      <v-text-field
                        label="Indirizzo"
                        v-model="destination.indirizzo"
                        name="indirizzo"
                        :rules="[$rules.maxlength(60)]"
                      />
                      <v-text-field
                        label="Città"
                        v-model="destination.citta"
                        name="citta"
                        :rules="[$rules.maxlength(60)]"
                      />
                      <v-select
                        label="Tipologia"
                        :items="tipi"
                        item-text="nome"
                        item-value="id"
                        v-model="destination.tipi"
                        multiple
                        chips
                        :rules="validTipologia"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 lg6>
                  <v-layout row wrap>
                    <v-flex xs12 sm8>
                      <v-text-field
                        label="CAP"
                        v-model="destination.cap"
                        name="cap"
                        :rules="[$rules.maxlength(20)]"
                      />
                      <v-text-field
                        label="Provincia"
                        v-model="destination.provincia"
                        name="provincia"
                        :rules="[$rules.maxlength(2)]"
                      />
                      <v-text-field
                        label="Stato"
                        v-model="destination.stato"
                        name="stato"
                        :rules="[$rules.maxlength(200)]"
                      />
                      <v-select
                        label="Stato"
                        :items="stati"
                        item-text="nome"
                        item-value="id"
                        v-model="destination.attivo"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 lg6>
                  <v-btn
                    class="ml-0"
                    color="primary"
                    :disabled="!validFormDestinazione"
                    id="salvaDestinazione"
                    @click.native="save">
                    Salva
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- conferma elimina destinazione -->
    <v-dialog v-model="eliminaDest" persistent max-width="250" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="headline">Sicuro?</v-card-title>
        <v-card-text>Stai per cancellare la destinazione <strong>{{ destination.presso }}</strong>.</v-card-text>
        <v-card-actions>
          <v-btn @click.native="eliminaDest = false">Annulla</v-btn>
          <v-btn color="error" @click.native="removeDest">Elimina</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip left>
      <v-btn slot="activator" color="pink darken-1" dark fab fixed right bottom @click="openDestDialog()">
        <v-icon color="white">add</v-icon>
      </v-btn>
      <span>Nuova destinazione - provenienza</span>
    </v-tooltip>
  </section>
</template>

<script>
import { $EventBus } from '@/eventBus'
import _clone from 'lodash/clone'
import _intersection from 'lodash/intersection'

export default {
  data: () => ({
    cliente: null,
    destinations: [],
    destination: {},
    tipi: [{ id: 0, nome: 'Destinazione' }, { id: 1, nome: 'Provenienza' }],
    stati: [{ id: 1, nome: 'Attiva' }, { id: 0, nome: 'Non attiva' }],
    showDest: false,
    eliminaDest: false,
    validFormDestinazione: false,
    validTipologia: [
      (v) => {
        return _intersection(v, [0, 1]).length > 0 || 'Obbligatoria'
      }
    ]
  }),
  computed: {
    modalTitle () {
      return this.destination._id ? 'Modifica ' + this.destination.presso : 'Nuova destinazione - provenienza'
    }
  },
  methods: {
    getDestinations () {
      this.$plsqlReadAll('destinazione', { cliente: this.cliente })
        .then(response => {
          this.destinations = response.data
        })
        .catch(err => {
          console.log('errore client', err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura destinazioni' })
        })
    },
    getAddr (d) {
      let addr = d.indirizzo
      if (d.cap || d.citta || d.provincia) {
        addr += ', ' + d.cap + ' ' + d.citta
        if (d.provincia) {
          addr += ' (' + d.provincia + ')'
        }
      }
      if (d.stato) {
        if (addr) {
          addr += ' - '
        }
        addr += d.stato
      }
      return addr
    },
    save () {
      if (this.$refs.formDestinazione.validate()) {
        this.showDest = false
        if (this.destination.tipi && this.destination.tipi.length) {
          if (this.destination.tipi.length > 1) {
            this.destination.tipo = 2
          } else {
            this.destination.tipo = this.destination.tipi[0]
          }
        } else {
          this.destination.tipo = 0
        }
        this.$plsqlSave('destinazione', this.destination)
          .then(response => {
            $EventBus.$emit('message', {
              type: 'success',
              text: 'Destinazione salvata'
            })
            this.getDestinations()
          })
          .catch(err => {
            console.log(err)
            $EventBus.$emit('message', { type: 'error', text: 'Errore salvataggio destinazione' })
          })
      }
    },
    removeDest () {
      this.$plsqlDelete('destinazione', this.destination._id)
        .then(response => {
          $EventBus.$emit('message', { type: 'success', text: 'Destinazione eliminata' })
          this.eliminaDest = false
          this.getDestinations()
        })
        .catch(err => {
          if (err.data.errCode === 'ORA-02292') {
            $EventBus.$emit('message', { type: 'error', text: 'Non è possibile cancellare la Destinazione perché usata' })
          } else {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          }
          this.eliminaDest = false
        })
    },
    closeDest () {
      this.showDest = false
      this.destination = {}
    },
    openDestDialog (destination) {
      this.destination = destination ? _clone(destination) : { cliente_id: this.cliente, tipo: 2, tipi: [0, 1] }
      if (!this.eliminaDest) {
        this.showDest = true
      }
      if (!destination) this.$refs.formDestinazione.reset()
    }
  },
  watch: {
    showDest: function (show) {
      if (show) {
        if (this.destination && Object.prototype.hasOwnProperty.call(this.destination, 'tipo')) {
          if (this.destination.tipo === 2) {
            this.destination.tipi = [0, 1]
          } else {
            this.destination.tipi = [this.destination.tipo]
          }
        }
      }
    }
  },
  mounted () {
    this.cliente = this.$route.params.id
    this.getDestinations()
  }
}
</script>
